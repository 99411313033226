import React, { Component } from 'react';

class Changelog extends Component {
  render() {
    return (
        <div>
          <h2>Changelog</h2>
        </div>
    );
  }
}

export default Changelog;